import Peripherals from "Utils/Workstation/Peripherals";
import * as MessageHelper from "Utils/Workstation/messageHelper";
import * as AudioPlayer from "Utils/Workstation/audioDecodePlayer";
import {
  INTERNAL_SDK_MESSAGES,
  LOCAL_STORAGE,
  SESSION_STORAGE,
  STREAM_TYPE,
  SWITCHMAN,
} from "Constants/global.constants";
import { Logger, Tracker, isSafari, isFirefox, isMobile } from "Utils";
import WebRTC from "Utils/Connection/webrtc";
import { getVerticalScrollDirection } from "Utils/Helpers/streaming.helpers";
import { STREAM_PLAYERS, VR_STATES } from "Constants/streaming.constants";
import {
  getAccessTokenFromLocalStorage,
  getItemFromLocalStorage,
  getItemFromSessionStorage,
  saveItemToLocalStorage,
} from "Utils/Helpers/storage.helpers";
import { BIT_RATES } from "Constants/AppStreaming.constants";
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import WebXRPolyfill from "webxr-polyfill";
import TestHelper from "./testHelper";
import SDKInternal from "./SDKInternal";

let checkFrameReceivedTimer;
let restartConnectionTimeout;

export default class WebsocketConnection {
  constructor(props) {
    const {
      password,
      controlWorkstationStatus,
      showNotification,
      autoStopEnabled,
      autoStopThreshold,
      setConnectingState,
      onConnection,
      onInitConnection,
      onDownload,
      onDownloadFailed,
      customResolution,
      setIframeSrc,
      endSession,
      onDuplicateTab,
      streamType,
      machineDetails,
      launchFlags,
      changeCursorPosition,
      setCursorImage,
      onWebRTCConnectionFailure,
      setTempFiles,
      onConnectionClosed,
      onTempFileDownloadCompleted,
      setShowKeyboardButton,
      enableGameModeFromSDK,
      disableGameModeFromSDK,
      onResetIdleTracker,
      submitStreamPreference,
      getSessionMetrics,
      onShutdown,
      blockClipboardEvents,
      translate,
      restartApplication,
      setVrState,
      setVrInstallationState,
      publicAccessId,
    } = props;
    this.password = password;
    this.controlWorkstationStatus = controlWorkstationStatus;
    this.showNotification = showNotification;
    this.autoStopEnabled = autoStopEnabled;
    this.autoStopThreshold = autoStopThreshold;
    this.setConnectingState = setConnectingState;
    this.onConnection = onConnection;
    this.onInitConnection = onInitConnection;
    this.onDownload = onDownload;
    this.onDownloadFailed = onDownloadFailed;
    this.retryConnectionCount = 0;
    this.closed = false;
    this.isFirstFrameMuxingCompleted = false;
    this.fps = 30;
    this.customResolution = customResolution;
    this.streamType = streamType || STREAM_TYPE.workstation;
    this.machineDetails = machineDetails;
    this.setIframeSrc = setIframeSrc;
    this.endSession = endSession;
    this.onDuplicateTab = onDuplicateTab;
    this.firstFrame = null;
    this.frameBuffer = [];
    this.isPlayerInitialized = false;
    this.audioCTX = null;
    this.micSource = null;
    this.micProcessor = null;
    this.launchFlags = launchFlags || "";
    this.changeCursorPosition = changeCursorPosition;
    this.setCursorImage = setCursorImage;
    this.onWebRTCConnectionFailure = onWebRTCConnectionFailure;
    this.setTempFiles = setTempFiles;
    this.onConnectionClosed = onConnectionClosed;
    this.onTempFileDownloadCompleted = onTempFileDownloadCompleted;
    this.setShowKeyboardButton = setShowKeyboardButton;
    this.enableGameModeFromSDK = enableGameModeFromSDK;
    this.disableGameModeFromSDK = disableGameModeFromSDK;
    this.onResetIdleTracker = onResetIdleTracker;
    this.submitStreamPreference = submitStreamPreference;
    this.getSessionMetrics = getSessionMetrics;
    this.onShutdown = onShutdown;
    this.blockClipboardEvents = blockClipboardEvents;
    this.translate = translate;
    this.xrSession = null;
    this.polyfill = new WebXRPolyfill();
    document.polyfill = this.polyfill;
    this.restartApplication = restartApplication;
    this.setVrState = setVrState;
    this.setVrInstallationState = setVrInstallationState;
    this.publicAccessId = publicAccessId;
    // TODO: Remove After Notify Implementation
    document.installVR = this.sendVRInstallationEvent;

    AudioPlayer.init();
    if (window.WebSocket) {
      this.init();
      Tracker.time({ type: "connect", start: true });
    } else {
      Logger.error("This browser does not support websockets.");
    }
    SDKInternal.start(this, this.internalSDKCallback);
    return this;
  }

  internalSDKCallback = (data) => {
    switch (data) {
      case INTERNAL_SDK_MESSAGES.resize:
        this.closeSockets();
        break;
      case INTERNAL_SDK_MESSAGES.showKeyboard:
        this.setShowKeyboardButton(true);
        break;
      case INTERNAL_SDK_MESSAGES.hideKeyboard:
        this.setShowKeyboardButton(false);
        break;
      case INTERNAL_SDK_MESSAGES.enableGameMode:
        this.enableGameModeFromSDK();
        break;
      case INTERNAL_SDK_MESSAGES.disableGameMode:
        this.disableGameModeFromSDK();
        break;
      case INTERNAL_SDK_MESSAGES.keepAlive:
        this.onResetIdleTracker();
        break;
      case INTERNAL_SDK_MESSAGES.shutdown:
        this.sendMessageToSocket(MessageHelper.generateApplicationEventShutdownMessage());
        setTimeout(this.onShutdown, 1000);
        break;
      case INTERNAL_SDK_MESSAGES.setQualityStandard:
        if (this.submitStreamPreference) {
          this.submitStreamPreference({ quality: "standard" });
        }
        break;
      case INTERNAL_SDK_MESSAGES.setQualityHigh:
        if (this.submitStreamPreference) {
          this.submitStreamPreference({ quality: "high" });
        }
        break;
      case INTERNAL_SDK_MESSAGES.setQualityModerate:
        if (this.submitStreamPreference) {
          this.submitStreamPreference({ quality: "moderate" });
        }
        break;
      case INTERNAL_SDK_MESSAGES.requestSessionInformation: {
        const data = this.prepareSessionInformation();
        const information = JSON.stringify(data);
        if (this.machineDetails.attributes.application.attributes.enterprise) {
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onSessionInformation + information);
        }
        break;
      }
      default:
        break;
    }
  };

  init = () => {
    Logger.log("Websocket|Init connection.", this.retryConnectionCount);
    if (this.onWebRTCConnectionFailure && this.retryConnectionCount >= 20) {
      Logger.log("Websocket|WebRTC connection failed.");
      this.onWebRTCConnectionFailure();
      return;
    }
    this.closeSockets();

    this.xrSession = null;

    this.currentPlayer = STREAM_PLAYERS.WEBRTC;

    this.videoElement = document.getElementById("player");

    if (this.streamType === STREAM_TYPE.workstation || this.streamType === STREAM_TYPE.organization) {
      this.vrElement = document.getElementById("vr-player");
      Logger.log("Websocket|VR Player", this.vrElement);
    }

    const turnUri =
      this.streamType === STREAM_TYPE.workstation
        ? this.machineDetails.attributes.machine.attributes.turn_uri
        : this.machineDetails.attributes.turn_uri;
    const turnPassword =
      this.streamType === STREAM_TYPE.workstation
        ? this.machineDetails.attributes.machine.attributes.turn_password
        : this.machineDetails.attributes.turn_password;
    const turnCredentials =
      this.streamType === STREAM_TYPE.workstation
        ? this.machineDetails.attributes.machine.attributes.turn_credentials
        : this.machineDetails.attributes.turn_credentials;
    this.websocket = new WebRTC(
      this.appSessionId(),
      this.videoElement,
      turnUri,
      turnPassword,
      this.streamType,
      this.machineDetails.id,
      turnCredentials,
      this.vrElement,
      this.createRequestStreamMessage(),
    );

    Logger.log("Websocket|Initialize websocket connection.", {
      player: document.getElementById("player"),
      websocket: this.websocket,
    });

    this.closed = false;
    this.websocket.binaryType = "arraybuffer";

    this.peripherals = new Peripherals(this.websocket, this.videoElement);
    this.testHelper = new TestHelper(this.websocket, this.videoElement);
    this.peripherals.setDisableKeyboardActions(false);
    this.peripherals.setDisableMetaKey(this.streamType === STREAM_TYPE.application);
    if (this.videoElement) {
      this.videoElement.style.cursor = "auto";
    }

    this.onInitConnection();
    this.initializeEventHandlers();
  };

  initializeEventHandlers = () => {
    this.websocket.onopen = (e) => {
      this.onOpen(e);
    };

    this.websocket.onclose = this.onWebsocketClose;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onerror = this.onError;

    if (this.streamType === STREAM_TYPE.workstation || this.streamType === STREAM_TYPE.organization) {
      // We need to set video muted or not on first user interaction
      // webrtc use video element to play audio
      // we are handling this with button click in appstreaming
      document.addEventListener("click", this.audioStateChanged, true);
    }
  };

  initializePlayer = () => {
    if (this.websocket?.readyState !== WebSocket.OPEN) {
      return;
    }

    document.getElementById("in-hidden").style.display = "flex";
    this.videoElement.style.display = "flex";
    this.initializeCanvasEventHandlers();
    this.setConnectingState();
    this.changeScrollPreference();
    this.isPlayerInitialized = true;
  };

  requestKeyFrame = () => {
    const requestKeyframePayload = MessageHelper.generateRequestKeyFrameEventMessage();
    this.sendMessageToSocket(requestKeyframePayload);
  };

  initializeCanvasEventHandlers = () => {
    document.addEventListener("contextmenu", Peripherals.preventEventDefault, true);
    this.videoElement.addEventListener("mousedown", this.peripherals.mouseHandler, true);
    this.videoElement.addEventListener("mouseup", this.peripherals.mouseHandler, true);
    this.videoElement.addEventListener("mousemove", this.peripherals.mouseMoveHandler, true);
    this.videoElement.addEventListener("wheel", this.peripherals.mouseWheelHandler, true);
    this.videoElement.addEventListener("mouseout", this.peripherals.mouseOutHandler, true);

    this.videoElement.addEventListener("touchstart", this.peripherals.simulateTouchStart, true);
    this.videoElement.addEventListener("touchend", this.peripherals.simulateTouchEnd, true);
    this.videoElement.addEventListener("touchmove", this.peripherals.simulateTouchMove, true);
    this.videoElement.addEventListener("touchcancel", this.peripherals.simulateTouchCancelled, true);

    this.videoElement.addEventListener("pointerdown", this.peripherals.simulatePointStart, true);
    this.videoElement.addEventListener("pointerup", this.peripherals.simulatePointEnd, true);
    this.videoElement.addEventListener("pointermove", this.peripherals.simulatePointMove, true);
    this.videoElement.addEventListener("pointercancel", this.peripherals.simulatePointCancelled, true);

    document.addEventListener("keydown", this.peripherals.keyDownHandler, true);
    document.addEventListener("keyup", this.peripherals.keyUpHandler, true);

    window.addEventListener("focus", this.peripherals.onFocus, true);

    if (isSafari) {
      window.addEventListener("resize", this.playerTransformForSafari, true);
    }
  };

  onOpen = () => {
    if (this.streamType === STREAM_TYPE.workstation || this.streamType === STREAM_TYPE.organization) {
      const autoStopPayload = MessageHelper.generateSetAutoStopEventPayload(
        this.autoStopEnabled,
        this.autoStopThreshold,
      );
      this.sendMessageToSocket(autoStopPayload);
      Logger.log("Websocket| Auto stop enabled", this.autoStopEnabled, this.autoStopThreshold);
      const setClientTokenPayload = MessageHelper.generateSetClientTokenEventPayload(getAccessTokenFromLocalStorage());
      Logger.log("Websocket| Set client token", setClientTokenPayload);
      this.sendMessageToSocket(setClientTokenPayload);
    } else {
      const autoStopPayload = MessageHelper.generateSetAutoStopEventPayload(false, 0);
      this.sendMessageToSocket(autoStopPayload);
    }

    if (this.streamType === STREAM_TYPE.application && this.machineDetails.attributes.keyboard_layout) {
      this.sendMessageToSocket(
        MessageHelper.generateKeyboardLayoutChangeEventMessage(this.machineDetails.attributes.keyboard_layout),
      );
    }

    if (getItemFromLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled)) {
      Logger.log("Websocket| VR Environment enabled");
      this.sendVRInstallationEvent();
    }
  };

  onClose = () => {
    Logger.log("Websocket| Onclose");
    if (this.onConnectionClosed) this.onConnectionClosed();

    this.clearEventHandlers();

    if (this.videoElement) {
      this.videoElement.style.display = "none";
    }
    this.controlWorkstationStatus();
    clearTimeout(checkFrameReceivedTimer);
    clearTimeout(restartConnectionTimeout);

    this.stopMicrophone();
    if (this.videoElement?.srcObject) {
      this.videoElement.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
        this.videoElement.srcObject.removeTrack(track);
      });
      this.videoElement.srcObject.getAudioTracks().forEach((track) => {
        track.stop();
        this.videoElement.srcObject.removeTrack(track);
      });
      this.videoElement.srcObject = null;
    }
    this.isPlayerInitialized = false;

    if (this.streamType === STREAM_TYPE.workstation || this.streamType === STREAM_TYPE.organization) {
      document.removeEventListener("click", this.audioStateChanged);
    }
  };

  onWebsocketClose = () => {
    this.onClose();
    this.restartConnection();
  };

  closeSockets = () => {
    if (this.websocket) this.websocket.close();
    this.websocket = null;
  };

  stopStreaming = () => {
    clearTimeout(restartConnectionTimeout);
    this.closed = true;
  };

  restartConnection = () => {
    clearTimeout(restartConnectionTimeout);
    if (!this.closed) {
      if (this.videoElement) {
        restartConnectionTimeout = setTimeout(this.init, 1000);
      }
    }
    this.retryConnectionCount += 1;
    Logger.log("Connection | restartConnection worked.", this.retryConnectionCount);
  };

  playerTransformForSafari = () => {
    // If browser is mobile there will be no resize
    if (isMobile && !isSafari) {
      return;
    }

    const actualRatio = this.customX / this.customY;
    const targetRatio = window.innerWidth / window.innerHeight;

    const adjustmentRatio = targetRatio / actualRatio;

    let adjustmentRatioY = 1;
    let adjustmentRatioX = 1;

    if (targetRatio > actualRatio) {
      // Change X
      adjustmentRatioX = adjustmentRatio;
    } else {
      // Change Y
      adjustmentRatioY = 1 / adjustmentRatio;
    }
    // this.videoElement.width = adjustmentRatioX * this.videoElement.width;
    // this.videoElement.height = adjustmentRatioY * this.videoElement.height;
    // this.videoElement.style.transform = `scale(${adjustmentRatioX},${adjustmentRatioY})`;
  };

  onMessage = (e) => {
    this.onStringTypeMessage(e);
  };

  onStringTypeMessage = (e) => {
    const message = MessageHelper.parseMessageString(e.data);
    Logger.log("Websocket|Received message:", e.data);
    const msgType = message.$type.toString();
    switch (msgType) {
      // Window resize is completed
      case MessageHelper.messageTypes.windowsResizedCompleted: {
        this.peripherals.setCustomResolution(message.x, message.y);
        this.customX = message.x;
        this.customY = message.y;
        this.initializePlayer();
        const diff = Tracker.time({ type: "connect", start: false });
        this.onConnection({ timeToConnect: diff });
        this.onOpen();
        this.retryConnectionCount = 0;
        this.sendMessageToSocket(MessageHelper.generateApplicationEventConnectionMessage(true));
        break;
      }
      case MessageHelper.messageTypes.clipboard:
        // We can not clear clipboard with javascript due to security.
        // So we just use one space to handle clipboard sync
        if (message.t === "") {
          message.t = " ";
        }
        if (this.blockClipboardEvents) {
          this.showNotification({ description: this.translate("organizationComputer.clipboardDisabledMessage") });
          return;
        }

        if (isSafari) {
          sessionStorage.setItem(SESSION_STORAGE.clipboardData, message.t);
          break;
        }
        if (isFirefox) {
          navigator.clipboard.writeText(message.t);
        }
        this.processClipboardMessage(message);
        break;
      case MessageHelper.messageTypes.cursorIconChanged:
        if (message.d) {
          this.videoElement.style.cursor = "auto";
        } else if (message.css !== "") {
          this.videoElement.style.cursor = message.css;
        } else if (message.ci === -1) {
          this.setCursorImage(null);
          this.videoElement.style.cursor = "auto";
        } else {
          this.videoElement.style.cursor = `url(data:image/png;base64,${message.cimg}) ${message.x} ${message.y}, auto`;
        }

        if (message.cimg !== "") {
          // so that default cursors can also be displayed on game mode
          this.setCursorImage(message.cimg);
        }

        break;
      case MessageHelper.messageTypes.fileInfo:
        Logger.log("Websocket| File info", message);
        if (message.at === MessageHelper.fileInfoMessageActionTypes.downloadFromUrl) {
          const { du, fn, s } = message;
          Logger.log("Websocket| Download file", du, fn, s);
          this.onDownload({ fileName: fn, fileSize: s, downloadUrl: du });
        } else if (message.at === MessageHelper.fileInfoMessageActionTypes.downloadFromUrlStarted) {
          const { fn, s } = message;
          Logger.log("Websocket| Download file started", fn, s);
          this.onDownload({ fileName: fn, fileSize: s });
        } else if (message.at === MessageHelper.fileInfoMessageActionTypes.fileUploadCompleted) {
          Logger.log("Websocket| Download file completed");
          if (this.onTempFileDownloadCompleted) {
            this.onTempFileDownloadCompleted();
          }
        } else if (message.at === MessageHelper.fileInfoMessageActionTypes.createFileError) {
          Logger.log("Websocket| Create file error", message);
          if (this.onDownloadFailed) {
            this.onDownloadFailed();
          }
        }
        break;
      case MessageHelper.messageTypes.info:
        this.onInfoMessage(message);
        break;
      case MessageHelper.messageTypes.ping:
        this.sendMessageToSocket(MessageHelper.generatePongEventPayload());
        break;
      case MessageHelper.messageTypes.pong:
        this.testHelper.onPongEvent();
        break;
      case MessageHelper.messageTypes.streamStats: {
        Logger.log("Websocket| stats", message.f, message.b, Date.now() - message.ms);
        break;
      }
      case MessageHelper.messageTypes.actionTrigger:
        this.onActionTriggerMessage(message);
        break;
      case MessageHelper.messageTypes.test:
        this.testHelper.onTestMessage(message);
        break;
      case MessageHelper.messageTypes.showCanvas:
        if (isSafari) {
          this.videoElement.style.objectFit = "contain";
        } else {
          this.videoElement.style.objectFit = "fill";
        }
        this.videoElement.style.display = "flex";
        this.videoElement.play().catch((e) => {
          Logger.error("Failed to play the video:", e);
        });
        if (this.vrElement) {
          this.vrElement.play().catch((e) => {
            Logger.error("Failed to play the video:", e);
          });
        }
        this.retryConnectionCount = 0;
        saveItemToLocalStorage(LOCAL_STORAGE.lastSuccessfulConnectionType, "webrtc");
        break;
      case MessageHelper.messageTypes.multipleConnection:
        this.closeSockets();
        this.onDuplicateTab();
        break;
      case MessageHelper.messageTypes.mousePosition:
        {
          const clientWidth = document?.documentElement?.clientWidth;
          const clientHeight = document?.documentElement?.clientHeight;

          const scaledX = (message.x / this.customX) * clientWidth;
          const scaledY = (message.y / this.customY) * clientHeight;

          this.changeCursorPosition(scaledX, scaledY);
        }
        break;
      case MessageHelper.messageTypes.applicationMessage:
        SDKInternal.sendToAllParentIframes(message.p);
        break;
      case MessageHelper.messageTypes.tempFilesList:
        if (this.setTempFiles) this.setTempFiles(message.f);
        break;
      case MessageHelper.messageTypes.vrToolsInstallationState: {
        const tool = message.t;
        const state = message.s;
        if (this.setVrInstallationState) {
          this.setVrInstallationState({
            tool,
            state,
          });
        }

        break;
      }
      case MessageHelper.messageTypes.vrToolsStatus: {
        const isToolsRunning = message.is;
        Logger.log("Websocket| VR Tools Status", isToolsRunning);
        this.setVrState(isToolsRunning ? VR_STATES.running : VR_STATES.stopped);
        break;
      }
      default:
        break;
    }
  };

  onInfoMessage = (message) => {
    switch (message.mt) {
      case MessageHelper.infoMessageTypes.log:
        Logger.log("Websocket|Info message received:", message.t);
        break;
      case MessageHelper.infoMessageTypes.toast:
        this.showNotification({ description: message.t });
        break;
      case MessageHelper.infoMessageTypes.loadingText:
        this.setConnectingState(message.t);
        break;
      default:
    }
  };

  onAudioStreamMessage = (message) => {
    if (message.data.type === "AUDIOSTREAM") {
      AudioPlayer.addChunk(message.data.payload);
    }
  };

  static onError(e) {
    Logger.error("Websocket Error: ", e);
  }

  sendMessageWindowsResized = () => {
    // NOTE: CONTROL THIS PART
    const resolution = this.sanitizeResolution();

    if (resolution.localResolution) {
      resolution.x = window.innerWidth;
      resolution.y = window.innerHeight;
    }

    if (this.videoElement) {
      this.videoElement.height = this.useWebRtc && resolution.localResolution ? window.innerHeight : resolution.y;
      this.videoElement.width = this.useWebRtc && resolution.localResolution ? window.innerWidth : resolution.x;
    }

    if (this.vrElement) {
      this.vrElement.height = this.useWebRtc ? window.innerHeight : resolution.y;
      this.videoElement.width = this.useWebRtc ? window.innerWidth : resolution.x;
    }

    Logger.log("Websocket|Window resized: ", this.customResolution, resolution.x, resolution.y);
    this.peripherals.windowsResizeHandler(resolution.x, resolution.y, this.retryConnectionCount);
  };

  setDisplayResolution = (resolution) => {
    this.customResolution = resolution;
    Logger.log("Websocket|Set custom resolution stream request:", resolution);
  };

  sanitizeResolution = () => {
    const appStreaming = this.streamType === STREAM_TYPE.application;
    if (!appStreaming && !this.customResolution) {
      saveItemToLocalStorage(LOCAL_STORAGE.customResolution, { x: 1920, y: 1080 });
      Logger.log("Websocket|Sanitize resolution:", 1920, 1080);
      return { x: 1920, y: 1080 };
    }

    if (!appStreaming && !this.customResolution.localResolution) {
      Logger.log("Websocket|Sanitize resolution:", this.customResolution.x, this.customResolution.y);
      return this.customResolution;
    }

    if (appStreaming && this.customResolution) {
      Logger.log("Websocket|Sanitize resolution:", this.customResolution.x, this.customResolution.y);
      return this.customResolution;
    }

    const width = 2 * Math.floor(window.innerWidth / 2);
    const height = 2 * Math.floor(window.innerHeight / 2);
    const newResolution = { x: width, y: height };

    Logger.log("Websocket|Sanitize resolution:", width, height);

    if (width < 500) {
      newResolution.x = width * 3;
      newResolution.y = height * 3;
    }
    return newResolution;
  };

  clearEventHandlers = () => {
    document.removeEventListener("keydown", this.peripherals.keyDownHandler, true);
    document.removeEventListener("keyup", this.peripherals.keyUpHandler, true);
    document.removeEventListener("contextmenu", Peripherals.preventEventDefault, true);
    window.removeEventListener("focus", this.peripherals.onFocus, true);

    if (this.videoElement) {
      this.videoElement.removeEventListener("mousedown", this.peripherals.mouseDownHandler, true);
      this.videoElement.removeEventListener("mouseup", this.peripherals.mouseUpHandler, true);
      this.videoElement.removeEventListener("mousemove", this.peripherals.mouseMoveHandler, true);
      this.videoElement.removeEventListener("wheel", this.peripherals.mouseWheelHandler, true);
      this.videoElement.removeEventListener("mouseout", this.peripherals.mouseOutHandler, true);

      this.videoElement.removeEventListener("touchstart", this.peripherals.simulateTouchStart, true);
      this.videoElement.removeEventListener("touchend", this.peripherals.simulateTouchEnd, true);
      this.videoElement.removeEventListener("touchmove", this.peripherals.simulateTouchMove, true);
      this.videoElement.removeEventListener("touchcancel", this.peripherals.simulateTouchCancelled, true);

      this.videoElement.removeEventListener("pointerdown", this.peripherals.simulatePointStart, true);
      this.videoElement.removeEventListener("pointerup", this.peripherals.simulatePointEnd, true);
      this.videoElement.removeEventListener("pointermove", this.peripherals.simulatePointMove, true);
      this.videoElement.removeEventListener("pointercancel", this.peripherals.simulatePointCancelled, true);
    }
    if (isSafari) {
      window.removeEventListener("resize", this.playerTransformForSafari, true);
    }
    this.peripherals.clearIntervals();
  };

  sendMessageToSocket = (msg) => {
    if (this.websocket?.readyState === WebSocket.OPEN) {
      this.websocket.send(msg);
    }
  };

  sendStopWarmUpEvent = () => {
    const payload = MessageHelper.generateStopWarmUpEventPayload();
    this.sendMessageToSocket(payload);
  };

  sendCtrlAltDeleteEvent = () => {
    const payload = MessageHelper.generateCtrlAltDelEventPayload();
    this.sendMessageToSocket(payload);
  };

  sendShowScreenKeyboardEvent = () => {
    const payload = MessageHelper.generateShowScreenKeyboardEventPayload();
    this.sendMessageToSocket(payload);
  };

  sendEnterPasswordEvent = () => {
    const payload = MessageHelper.generatePasteToRemoteEventPayload(this.password, true);
    this.sendMessageToSocket(payload);
  };

  sendApplicationEvent = (payload) => {
    const wrappedPayload = MessageHelper.generateApplicationEventMessage(payload);
    this.sendMessageToSocket(wrappedPayload);
  };

  pasteToRemote = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        const payload = MessageHelper.generatePasteToRemoteEventPayload(text, false);
        this.sendMessageToSocket(payload);
        this.showNotification({ description: "Clipboard synced" });
      })
      .catch((error) => {
        console.error("Failed to read clipboard contents:", error);
      });
  };

  setDisableKeyboardActions = (disable) => {
    this.peripherals.setDisableKeyboardActions(disable);
  };

  pasteTextToRemote = (text) => {
    const payload = MessageHelper.generatePasteToRemoteEventPayload(text, false);
    this.sendMessageToSocket(payload);
    this.showNotification({ description: "Clipboard synced" });
  };

  copyFromRemote = () => {
    const payload = MessageHelper.generateCopyFromRemoteEventPayload();
    this.sendMessageToSocket(payload);
  };

  processClipboardMessage = (message) => {
    // Create new element
    const el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = message.t;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
    if (message.t !== " ") {
      this.showNotification({ description: "Clipboard synced" });
    }
  };

  startMicrophone = async () => {
    if (this.audioCTX === null) {
      this.audioCTX = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (this.audioCTX.state === "suspended") {
      await this.audioCTX.resume();
    }

    const audioInputDevice = getItemFromLocalStorage(LOCAL_STORAGE.audioInputDevice, null);

    const constraints = {
      sampleRate: isSafari ? 44100 : 48000,
      channelCount: 1,
      latency: 0.05,
      echoCancellation: true,
      deviceId: audioInputDevice ? { exact: audioInputDevice.deviceId } : "communications",
    };
    window.navigator.mediaDevices
      .getUserMedia({ audio: constraints, video: false })
      .then((stream) => {
        this.websocket.startMicrophone(stream);
      })
      .catch((err) => {
        Logger.log(`Could not add audio input: ${err}`);
        this.micSource = null;
        this.micProcessor = null;
      });
  };

  stopMicrophone = async () => {
    if (this.websocket) {
      this.websocket.stopMicrophone();
    }
  };

  changeMicrophoneState = async (state) => {
    if (state) {
      if (this.micSource === null && this.micProcessor === null) {
        await this.startMicrophone();
      }
    } else {
      await this.stopMicrophone();
    }
  };

  audioStateChanged = () => {
    const state =
      this.streamType === STREAM_TYPE.application
        ? getItemFromSessionStorage(SESSION_STORAGE.soundEnabled, false)
        : getItemFromLocalStorage(LOCAL_STORAGE.soundEnabled, false);

    const audioOutputDevice = getItemFromLocalStorage("audioOutputDevice", null);

    if (this.videoElement) {
      this.videoElement.muted = !state;
      const audioTrack = this.videoElement?.srcObject?.getAudioTracks()?.[0];
      if (audioTrack) {
        audioTrack.enabled = true;
      }

      if (audioOutputDevice && this.videoElement?.setSinkId) {
        this.videoElement.setSinkId(audioOutputDevice.deviceId);
      }
    }
  };

  changeScrollPreference = () => {
    const scrollPreference = getVerticalScrollDirection();
    this.peripherals.setVerticalScrollDirection(scrollPreference);
  };

  changeCmdPreference = (cmdPreference) => {
    saveItemToLocalStorage(LOCAL_STORAGE.switchCmdPreference, cmdPreference);
    this.peripherals.setSwitchCmdCtrlPreference(cmdPreference);
  };

  changeGameMode = (request, x, y) => {
    if (isMobile) {
      return;
    }
    if (request) {
      if (x && y) {
        this.peripherals.calculateMouseXandY(x, y);
      }
      this.videoElement.focus();
      this.changeCursorPosition(this.peripherals.mouseX, this.peripherals.mouseY);
      this.videoElement.requestPointerLock();
      const payload = MessageHelper.generateMouseEventPayload(
        this.peripherals.mouseCX,
        this.peripherals.mouseCY,
        0,
        0,
        null,
        false,
        false,
      );
      this.sendMessageToSocket(payload);
      this.peripherals.changeGameMode(true);
      this.peripherals.ignoreFirstMovementChange(true);
    } else {
      this.changeCursorPosition(this.peripherals.mouseX, this.peripherals.mouseY);
      document.exitPointerLock();
      this.peripherals.ignoreFirstMovementChange(false);
      const payload = MessageHelper.generateMouseEventPayload(
        this.peripherals.mouseCX,
        this.peripherals.mouseCY,
        0,
        0,
        null,
        false,
        false,
      );
      this.sendMessageToSocket(payload);
      this.peripherals.changeGameMode(false);
    }
  };

  createRequestStreamMessage = () => {
    const advancedStreamSettings = getItemFromLocalStorage(LOCAL_STORAGE.advancedStreamSettings, {});
    const message = {
      quality: advancedStreamSettings.quality?.toLowerCase(),
      player: this.currentPlayer,
      publicAccessId: this.publicAccessId,
    };
    Object.assign(message, advancedStreamSettings);
    Object.assign(message, {
      password: this.password,
    });
    const resolution = this.sanitizeResolution();

    if (this.videoElement) {
      this.videoElement.height = window.innerHeight;
      this.videoElement.width = window.innerWidth;
    }
    Object.assign(message, {
      resolutionX: resolution.x,
      resolutionY: resolution.y,
      retryCount: this.retryConnectionCount,
    });

    switch (this.streamType) {
      case STREAM_TYPE.application: {
        const launchFlags = this.createLaunchFlags();
        const sessionId =
          this.launchFlags && this.launchFlags.includes(SWITCHMAN)
            ? this.appSessionId() + launchFlags
            : this.appSessionId();

        const appStreamConfig = {
          st: 1,
          an: this.machineDetails.attributes.application.attributes.executable_name,
          ad: this.machineDetails.attributes.application.attributes.path,
          aa: launchFlags,
          ara: this.machineDetails.attributes.application.attributes.restart_arguments || "",
          asid: sessionId,
          aeh: this.machineDetails.attributes.app_executable_helpers,
        };

        Object.assign(message, appStreamConfig);
        Logger.log("Websocket|Request stream message:", message);
        return message;
      }
      default:
        Object.assign(message, { st: 0 });
        Logger.log("Websocket|Request stream message:", message);
        return message;
    }
  };

  createLaunchFlags = () => {
    let launchFlags = "";
    if (this.machineDetails.attributes.application.attributes.launch_arguments) {
      launchFlags = this.machineDetails.attributes.application.attributes.launch_arguments;
    }

    if (this.launchFlags) {
      if (this.launchFlags.includes(SWITCHMAN)) {
        const parameter = this.launchFlags.split(":")[1];
        launchFlags = `${launchFlags} ${parameter}`;
      } else {
        launchFlags = `${launchFlags} ${this.launchFlags}`;
      }
    }
    return launchFlags;
  };

  appSessionId = (override = false) => {
    if (!this.restartApplication) {
      return "";
    }

    let appSessionId = getItemFromLocalStorage(LOCAL_STORAGE.appSessionId);
    if (!appSessionId || override) {
      appSessionId = Math.random().toString(36).substr(2, 9);
    }
    saveItemToLocalStorage(LOCAL_STORAGE.appSessionId, appSessionId);
    if (this.streamType === STREAM_TYPE.application) {
      appSessionId = `${appSessionId}:${this.machineDetails.attributes.active_stream_id}`;
    }
    return appSessionId;
  };

  onActionTriggerMessage = (message) => {
    switch (message.at) {
      case MessageHelper.actionTriggerTypes.showVimeoPopUp:
      case MessageHelper.actionTriggerTypes.showYouTubePopUp:
        this.setIframeSrc(message.p);
        break;
      case MessageHelper.actionTriggerTypes.endSession:
        this.appSessionId(true);
        this.closeSockets();
        this.endSession();
        break;
      case MessageHelper.actionTriggerTypes.openUrl:
        window.open(message.p, "_blank");
        break;
      case MessageHelper.actionTriggerTypes.showKeyboard:
        this.setShowKeyboardButton(true);
        break;
      case MessageHelper.actionTriggerTypes.hideKeyboard:
        this.setShowKeyboardButton(false);
        break;
      case MessageHelper.actionTriggerTypes.enableGameMode:
        this.enableGameModeFromSDK();
        break;
      case MessageHelper.actionTriggerTypes.disableGameMode:
        this.disableGameModeFromSDK();
        break;
      case MessageHelper.actionTriggerTypes.keepAlive:
        if (this.onResetIdleTracker) {
          this.onResetIdleTracker();
        }
        break;
      case MessageHelper.actionTriggerTypes.resize:
        this.closeSockets();
        break;
      case MessageHelper.actionTriggerTypes.focusIframe:
        SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.focusIframe);
        break;
      case MessageHelper.actionTriggerTypes.setQuality:
        if (this.submitStreamPreference) {
          const bitrate = BIT_RATES[message.p];
          if (bitrate) {
            this.submitStreamPreference({ quality: message.p });
          }
        }
        break;
      case MessageHelper.actionTriggerTypes.requestSessionInformation: {
        const payload = MessageHelper.generateApplicationEventSessionInformationMessage(
          this.prepareSessionInformation(),
        );
        if (this.machineDetails.attributes.application.attributes.enterprise) {
          this.sendMessageToSocket(payload);
        }
        break;
      }
      case MessageHelper.actionTriggerTypes.onShutDown:
        setTimeout(this.onShutdown, 2000);
        break;
      case MessageHelper.actionTriggerTypes.onPreloadedFilesDownloadCompleted:
        SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onPreloadedFilesDownloadCompleted);
        break;
      case MessageHelper.actionTriggerTypes.vrToolsUpdateNeeded:
        if (this.xrSession) {
          this.xrSession.end();
        }
        this.setVrState(VR_STATES.installing);
        break;
      case MessageHelper.actionTriggerTypes.vrToolsInstallationSuccess:
        this.setVrInstallationState({});
        break;
      case MessageHelper.actionTriggerTypes.vrToolsInstallationFailed:
        this.setVrState(VR_STATES.failed);
        break;
      case MessageHelper.actionTriggerTypes.vrToolsNotRunning:
        if (this.xrSession) {
          this.xrSession.end();
        }
        this.setVrState(VR_STATES.enabled);
        break;
      default:
        break;
    }
  };

  sendDownloadFileEvent = (url, filename) => {
    const message = MessageHelper.generateDownloadFileEventPayload(url, filename);
    this.sendMessageToSocket(message);
  };

  sendListTempFilesEvent = () => {
    const message = MessageHelper.generateRequestTempFilesEventMessage();
    this.sendMessageToSocket(message);
  };

  sendDownloadTempFileEvent = (fileName) => {
    const message = MessageHelper.generateRequestTempFileDownloadEventMessage(fileName);
    this.sendMessageToSocket(message);
  };

  openVagonExplorer = () => {
    if (this.streamType === STREAM_TYPE.application) {
      const message = MessageHelper.generateOpenVagonExplorerEventMessage();
      this.sendMessageToSocket(message);
    }
  };

  prepareSessionInformation = () => {
    if (this.streamType !== STREAM_TYPE.application) {
      return {};
    }
    let session = {};
    if (this.getSessionMetrics && this.machineDetails.attributes.user_session_data) {
      session = this.getSessionMetrics();
    }
    return {
      session: {
        ping: session.ping,
        os: session.os,
        device_type: session.device_type,
      },
      machine: {
        status: this.machineDetails.attributes.status,
        friendly_status: this.machineDetails.attributes.friendly_status,
        connection_status: this.machineDetails.attributes.connection_status,
        region: this.machineDetails.attributes.region,
        uid: this.machineDetails.id,
        application_id: this.machineDetails.attributes.application.id,
        stream_id: this.machineDetails.attributes.stream_id,
        machine_id: this.machineDetails.attributes.machine_id,
      },
    };
  };

  setBlockClipboardEvents = (value) => {
    this.blockClipboardEvents = value;
  };

  sendToggleVRModeEvent = () => {
    const mode = this.videoElement.style.display === "flex";
    const message = MessageHelper.generateToggleVRModeEventMessage(mode);
    this.sendMessageToSocket(message);
    if (!mode) {
      this.videoElement.style.display = "flex";
      this.vrElement.style.display = "none";
    } else {
      this.videoElement.style.display = "none";
      this.vrElement.style.display = "flex";
      this.fovSended = false;
      navigator.xr
        .requestSession("immersive-vr", {
          requiredFeatures: ["local-floor"],
        })
        .then((session) => {
          this.xrSession = session;
          this.onXRSessionStarted(session);
        });
    }
  };

  toggleVREnvoriment = (state) => {
    if (state) {
      this.sendVRInstallationEvent();
    } else {
      this.sendStopVRToolsEvent();
    }
  };

  sendVRInstallationEvent = () => {
    if (this.streamType !== STREAM_TYPE.workstation) {
      return;
    }
    const message = MessageHelper.generateVRInstallationEventMessage();
    this.sendMessageToSocket(message);
  };

  sendStopVRToolsEvent = () => {
    const message = MessageHelper.generateStopVRToolsEventMessage();
    this.sendMessageToSocket(message);
  };

  // TODO: Call this function for VR Tools Status
  sendIsVrToolsRunningEvent = () => {
    const message = MessageHelper.generateIsVrToolsRunningEventMessage();
    this.sendMessageToSocket(message);
  };

  applyVrBitrate = () => {
    const vrBitRate = getItemFromLocalStorage(LOCAL_STORAGE.vrBitRate, 15000000);
    if (this.xrSession) {
      const message = MessageHelper.generateVRBitrateEventMessage(vrBitRate);
      this.sendMessageToSocket(message);
    }
  };

  onXRSessionStarted = (session) => {
    const vrBitRate = getItemFromLocalStorage(LOCAL_STORAGE.vrBitRate, 15000000);
    const message = MessageHelper.generateVRBitrateEventMessage(vrBitRate);
    this.sendMessageToSocket(message);
    document.xrSessionRef = session;
    Logger.log("Websocket|XR Session started", session);
    this.xrSession.addEventListener("end", () => {
      this.onXrSessionEnded();
    });

    const canvas = document.createElement("canvas");
    this.gl = canvas.getContext("webgl2", {
      xrCompatible: true,
    });

    this.xrSession.updateRenderState({
      baseLayer: new window.XRWebGLLayer(this.xrSession, this.gl),
    });

    // setup vertex shader
    const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
    this.gl.shaderSource(vertexShader, this.vertexShader());
    this.gl.compileShader(vertexShader);
    const vrtx_message = this.gl.getShaderInfoLog(vertexShader);
    if (vrtx_message.length > 0) {
      Logger.log("Websocket| WebGL Vertex Shader Error", vrtx_message);
    } else {
      Logger.log("Websocket| WebGL Vertex Shader OK ");
    }
    // setup fragment shader
    const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
    this.gl.shaderSource(fragmentShader, this.fragmentShader());
    this.gl.compileShader(fragmentShader);
    const frag_message = this.gl.getShaderInfoLog(fragmentShader);
    if (frag_message.length > 0) {
      Logger.log("Websocket| WebGL Fragment Shader Error", frag_message);
    } else {
      Logger.log("Websocket| WebGL Fragment Shader OK ");
    }
    // setup GLSL program
    const shaderProgram = this.gl.createProgram();
    this.gl.attachShader(shaderProgram, vertexShader);
    this.gl.attachShader(shaderProgram, fragmentShader);
    this.gl.linkProgram(shaderProgram);
    const prog_message = this.gl.getProgramInfoLog(shaderProgram);
    if (prog_message.length > 0) {
      Logger.log("Websocket| WebGL Program Error", prog_message);
    } else {
      Logger.log("Websocket| WebGL Program OK ");
    }
    this.gl.useProgram(shaderProgram);

    // look up where vertex data needs to go
    this.positionLocation = this.gl.getAttribLocation(shaderProgram, "a_position");
    this.texcoordLocation = this.gl.getAttribLocation(shaderProgram, "a_texCoord");
    // Create a buffer to put three 2d clip space points in
    this.positionBuffer = this.gl.createBuffer();
    // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);

    // Turn on the position attribute
    this.gl.enableVertexAttribArray(this.positionLocation);
    // Create a texture.
    const texture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    // Set the parameters so we can render any size image.
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);

    this.texcoordBuffer = this.gl.createBuffer();
    // lookup uniforms
    this.resolutionLocation = this.gl.getUniformLocation(shaderProgram, "u_resolution");
    this.offsetLocation = this.gl.getUniformLocation(shaderProgram, "u_offset");

    this.xrSession
      .requestReferenceSpace("local-floor")
      .then((refSpace) => {
        Logger.log("Websocket|XR Reference Space", refSpace);
        this.xrRefSpace = refSpace;
        this.xrSession.requestAnimationFrame((time, frame) => this.onXrFrame(time, frame));
      })
      .catch((error) => {
        Logger.log("Websocket|XR Reference Space Error", error);
        this.xrSession.requestReferenceSpace("local").then((refSpace) => {
          Logger.log("Websocket|XR Reference Space", refSpace);
          this.xrRefSpace = refSpace;
          this.xrSession.requestAnimationFrame((time, frame) => this.onXrFrame(time, frame));
        });
      });
  };

  onXrSessionEnded = () => {
    Logger.log("Websocket|XR Session ended");
    this.xrSession = null;
    this.refSpace = null;
    this.sendToggleVRModeEvent();
  };

  calculateFov = (proj) => {
    Logger.log("Webxr proj", proj);
    const rightTan = (1 + proj[8]) / proj[0];
    const leftTan = (1 - proj[8]) / proj[0];
    const upTan = (1 + proj[9]) / proj[5];
    const downTan = (1 - proj[9]) / proj[5];
    Logger.log("Webxr fov", leftTan, rightTan, upTan, downTan);

    const fov = {
      left: Math.abs(Math.atan(leftTan)) * -1,
      right: Math.abs(Math.atan(rightTan)),
      up: Math.atan(upTan),
      down: Math.abs(Math.atan(downTan)) * -1,
    };

    Logger.log("Webxr fov angles", fov);

    return fov;
  };

  onXrFrame = (time, frame) => {
    const devices = [];
    const inputs = [];
    const pose = frame.getViewerPose(this.xrRefSpace);
    if (pose) {
      if (!this.fovSended) {
        const t0 = pose.views[0].transform.position;
        const t1 = pose.views[1].transform.position;
        const xd = t0.x - t1.x;
        const yd = t0.y - t1.y;
        const zd = t0.z - t1.z;
        const ipd = xd * xd + yd * yd + zd * zd;
        Logger.log("Webxr eye ipd", Math.sqrt(ipd));

        const left = this.calculateFov(pose.views[0].projectionMatrix);
        const right = this.calculateFov(pose.views[1].projectionMatrix);
        const fovPayload = MessageHelper.generateXRFovEventMessage(ipd, left, right);
        this.sendMessageToSocket(fovPayload);
        this.fovSended = true;
      }

      // Logger.log("Websocket|XR Pose", pose.linearVelocity);
      // Note: head->0 and left/right hand->1,2
      devices.push({
        id: "/user/head",
        dm: {
          p: {
            o: [
              pose.transform.orientation.x,
              pose.transform.orientation.y,
              pose.transform.orientation.z,
              pose.transform.orientation.w,
            ],
            p: [pose.transform.position.x, pose.transform.position.y, pose.transform.position.z],
          },
        },
      });
    }

    this.xrSession.inputSources.forEach((source, _index, _array) => {
      if (source.gamepad) {
        const gamepadPose = frame.getPose(source.gripSpace, this.xrRefSpace);
        if (gamepadPose) {
          let handId = "";
          switch (source.handedness) {
            case "left":
              handId = "/user/hand/left";
              break;
            case "right":
              handId = "/user/hand/right";
              break;
            default:
              break;
          }

          if (handId !== "") {
            devices.push({
              id: handId,
              dm: {
                p: {
                  o: [
                    gamepadPose.transform.orientation.x,
                    gamepadPose.transform.orientation.y,
                    gamepadPose.transform.orientation.z,
                    gamepadPose.transform.orientation.w,
                  ],
                  p: [
                    gamepadPose.transform.position.x,
                    gamepadPose.transform.position.y,
                    gamepadPose.transform.position.z,
                  ],
                },
              },
            });
          }
          const mapping = source.handedness === "left" ? this.oculusMapping.left : this.oculusMapping.right;
          Object.keys(mapping).forEach((key) => {
            const map = mapping[key];
            const input = {
              id: key,
              vt: map.valueType === "boolean" ? 0 : 1,
            };
            const reverse =
              key === "/user/hand/left/input/thumbstick/y" || key === "/user/hand/right/input/thumbstick/y";
            const value = reverse ? -1 * source.gamepad[map.list][map.index] : source.gamepad[map.list][map.index];

            try {
              if (map.value === "self") {
                input.v = value.toString();
              } else {
                input.v = value[map.value].toString();
              }
              inputs.push(input);
            } catch {
              Logger.log("Websocket|XR Input Source Error", map, source.gamepad);
            }
          });

          if (!document.inputRef) {
            document.inputRef = inputs;
          }
        }
      }
      // Logger.log("Websocket|XR Input Source", source, index, array);
    });

    const payload = MessageHelper.generateXRDevicesEventMessage(time, devices, inputs);
    // Logger.log("Websocket|XR Devices", payload);
    this.sendMessageToSocket(payload);

    const glLayer = this.xrSession.renderState.baseLayer;
    // If we do have a valid pose, bind the WebGL layer's framebuffer,
    // which is where any content to be displayed on the XRDevice must be
    // rendered.
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, glLayer.framebuffer);

    // Upload the image into the texture. WebGL knows how to extract the current frame from the video element
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.vrElement);

    this.render();

    if (this.xrSession) {
      this.xrSession.requestAnimationFrame((time, frame) => this.onXrFrame(time, frame));
    }
  };

  render = () => {
    if (!this.gl) {
      return;
    }

    const glLayer = this.xrSession.renderState.baseLayer;
    this.gl.viewport(0, 0, glLayer.framebufferWidth, glLayer.framebufferHeight);
    this.gl.uniform4f(this.offsetLocation, 1.0, 1.0, 0.0, 0.0);

    // Set rectangle
    // prettier-ignore
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array([
        0, 0,
        this.vrElement.videoWidth, 0,
        0, this.vrElement.videoHeight,
        0, this.vrElement.videoHeight,
        this.vrElement.videoWidth, 0,
        this.vrElement.videoWidth, this.vrElement.videoHeight
      ]),
      this.gl.STATIC_DRAW
    );

    // Provide texture coordinates for the rectangle
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0]),
      this.gl.STATIC_DRAW,
    );

    let size; // components per iteration
    let type; // the data type
    let normalize; // normalize the data
    let stride; // 0 = move forward size * sizeof(type) each iteration to get the next position
    let offset; // start position of the buffer

    // Bind the position buffer.
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    size = 2; // 2 components per iteration
    type = this.gl.FLOAT; // the data is 32bit floats
    normalize = false; // don't normalize the data
    stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    offset = 0; // start at the beginning of the buffer
    this.gl.vertexAttribPointer(this.positionLocation, size, type, normalize, stride, offset);
    // Turn on the texcoord attribute
    this.gl.enableVertexAttribArray(this.texcoordLocation);
    // bind the texcoord buffer.
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);
    // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
    size = 2; // 2 components per iteration
    type = this.gl.FLOAT; // the data is 32bit floats
    normalize = false; // don't normalize the data
    stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    offset = 0; // start at the beginning of the buffer
    this.gl.vertexAttribPointer(this.texcoordLocation, size, type, normalize, stride, offset);
    // set the resolution
    this.gl.uniform2f(this.resolutionLocation, this.vrElement.videoWidth, this.vrElement.videoHeight);
    // draw the rectangle.
    const primitiveType = this.gl.TRIANGLES;
    const count = 6;
    offset = 0;
    this.gl.drawArrays(primitiveType, offset, count);
  };

  vertexShader = () => {
    return `
attribute vec2 a_position;
attribute vec2 a_texCoord;

// input
uniform vec2 u_resolution;
uniform vec4 u_offset;

//
varying vec2 v_texCoord;

void main() {
   // convert the rectangle from pixels to 0.0 to 1.0
   vec2 zeroToOne = a_position / u_resolution;

   // convert from 0->1 to 0->2
   vec2 zeroToTwo = zeroToOne * 2.0;

   // convert from 0->2 to -1->+1 (clipspace)
   vec2 clipSpace = zeroToTwo - 1.0;

   gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
   // pass the texCoord to the fragment shader
   // The GPU will interpolate this value between points.
   v_texCoord = (a_texCoord * u_offset.xy) + u_offset.zw;
}
`;
  };

  fragmentShader2 = () => {
    return `
precision mediump float;

// our texture
uniform sampler2D u_image;

// the texCoords passed in from the vertex shader.
varying vec2 v_texCoord;

void main() {
   gl_FragColor = texture2D(u_image, v_texCoord);
}
`;
  };

  fragmentShader = () => {
    return `
precision highp float;

uniform sampler2D u_image;
varying vec2 v_texCoord;

const vec2 TARGET_RESOLUTION = vec2(1856.0, 2016.0);
const vec2 OPTIMIZED_RESOLUTION = vec2(1088.0, 1056.0);
const vec2 EYE_SIZE_RATIO = vec2(1.0, 0.99242437);
const vec2 CENTER_SIZE = vec2(0.44827586, 0.3998016);
const vec2 CENTER_SHIFT = vec2(0.40625, 0.10743801);
const vec2 EDGE_RATIO = vec2(4.0, 5.0);

vec2 TextureToEyeUV(vec2 textureUV, bool isRightEye) {
    // flip distortion horizontally for right eye
    // left: x * 2; right: (1 - x) * 2
    return vec2((textureUV.x + float(isRightEye) * (1. - 2. * textureUV.x)) * 2., textureUV.y);
}

vec2 EyeToTextureUV(vec2 eyeUV, bool isRightEye) {
    // left: x / 2; right 1 - (x / 2)
    return vec2(eyeUV.x * 0.5 + float(isRightEye) * (1. - eyeUV.x), eyeUV.y);
}

// uniform sampler2D tex0;
// in vec2 uv;
// out vec4 color;
void main() {
    bool isRightEye = v_texCoord.x > 0.5;
    vec2 eyeUV = TextureToEyeUV(v_texCoord, isRightEye);

    vec2 c0 = (1. - CENTER_SIZE) * 0.5;
    vec2 c1 = (EDGE_RATIO - 1.) * c0 * (CENTER_SHIFT + 1.) / EDGE_RATIO;
    vec2 c2 = (EDGE_RATIO - 1.) * CENTER_SIZE + 1.;

    vec2 loBound = c0 * (CENTER_SHIFT + 1.);
    vec2 hiBound = c0 * (CENTER_SHIFT - 1.) + 1.;
    vec2 underBound = vec2(eyeUV.x < loBound.x, eyeUV.y < loBound.y);
    vec2 inBound = vec2(loBound.x < eyeUV.x && eyeUV.x < hiBound.x,
                        loBound.y < eyeUV.y && eyeUV.y < hiBound.y);
    vec2 overBound = vec2(eyeUV.x > hiBound.x, eyeUV.y > hiBound.y);

    vec2 center = (eyeUV - c1) * EDGE_RATIO / c2;

    vec2 loBoundC = c0 * (CENTER_SHIFT + 1.) / c2;
    vec2 hiBoundC = c0 * (CENTER_SHIFT - 1.) / c2 + 1.;

    vec2 leftEdge = (-(c1 + c2 * loBoundC) / loBoundC +
                    sqrt(abs(((c1 + c2 * loBoundC) / loBoundC) * ((c1 + c2 * loBoundC) / loBoundC) +
                        4. * c2 * (1. - EDGE_RATIO) / (EDGE_RATIO * loBoundC) * eyeUV))) /
                    (2. * c2 * (1. - EDGE_RATIO)) * (EDGE_RATIO * loBoundC);
    vec2 rightEdge =
        (-(c2 - EDGE_RATIO * c1 - 2. * EDGE_RATIO * c2 + c2 * EDGE_RATIO * (1. - hiBoundC) +
        EDGE_RATIO) /
            (EDGE_RATIO * (1. - hiBoundC)) +
        sqrt(abs(((c2 - EDGE_RATIO * c1 - 2. * EDGE_RATIO * c2 + c2 * EDGE_RATIO * (1. - hiBoundC) +
                EDGE_RATIO) /
            (EDGE_RATIO * (1. - hiBoundC))) *
                ((c2 - EDGE_RATIO * c1 - 2. * EDGE_RATIO * c2 +
                    c2 * EDGE_RATIO * (1. - hiBoundC) + EDGE_RATIO) /
                (EDGE_RATIO * (1. - hiBoundC))) -
            4. * ((c2 * EDGE_RATIO - c2) * (c1 - hiBoundC + hiBoundC * c2) /
                        (EDGE_RATIO * (1. - hiBoundC) * (1. - hiBoundC)) -
                    eyeUV * (c2 * EDGE_RATIO - c2) / (EDGE_RATIO * (1. - hiBoundC)))))) /
        (2. * c2 * (EDGE_RATIO - 1.)) * (EDGE_RATIO * (1. - hiBoundC));

    vec2 uncompressedUV = underBound * leftEdge + inBound * center + overBound * rightEdge;

    gl_FragColor = texture2D(u_image, EyeToTextureUV(uncompressedUV * EYE_SIZE_RATIO, isRightEye));
}
`;
  };

  oculusMapping = {
    left: {
      "/user/hand/left/input/trigger/value": {
        list: "buttons",
        index: 0,
        value: "value",
        valueType: "float",
      },
      "/user/hand/left/input/y/click": {
        list: "buttons",
        index: 5,
        value: "pressed",
        valueType: "boolean",
      },
      "/user/hand/left/input/thumbstick/click": {
        list: "buttons",
        index: 3,
        value: "pressed",
        valueType: "boolean",
      },
      "/user/hand/left/input/y/touch": {
        list: "buttons",
        index: 5,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/left/input/squeeze/value": {
        list: "buttons",
        index: 1,
        value: "value",
        valueType: "float",
      },
      "/user/hand/left/input/thumbstick/x": {
        list: "axes",
        index: 2,
        value: "self",
        valueType: "float",
      },
      "/user/hand/left/input/x/touch": {
        list: "buttons",
        index: 4,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/left/input/thumbstick/touch": {
        list: "buttons",
        index: 3,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/left/input/trigger/touch": {
        list: "buttons",
        index: 0,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/left/input/thumbrest/touch": {
        list: "buttons",
        index: 6,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/left/input/thumbstick/y": {
        list: "axes",
        index: 3,
        value: "self",
        valueType: "float",
      },
      "/user/hand/left/input/x/click": {
        list: "buttons",
        index: 4,
        value: "pressed",
        valueType: "boolean",
      },
    },
    right: {
      "/user/hand/right/input/a/click": {
        list: "buttons",
        index: 4,
        value: "pressed",
        valueType: "boolean",
      },
      "/user/hand/right/input/trigger/touch": {
        list: "buttons",
        index: 0,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/right/input/a/touch": {
        list: "buttons",
        index: 4,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/right/input/thumbstick/touch": {
        list: "buttons",
        index: 3,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/right/input/thumbstick/click": {
        list: "buttons",
        index: 3,
        value: "pressed",
        valueType: "boolean",
      },
      "/user/hand/right/input/trigger/value": {
        list: "buttons",
        index: 0,
        value: "value",
        valueType: "float",
      },
      "/user/hand/right/input/thumbstick/x": {
        list: "axes",
        index: 2,
        value: "self",
        valueType: "float",
      },
      "/user/hand/right/input/b/touch": {
        list: "buttons",
        index: 5,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/right/input/squeeze/value": {
        list: "buttons",
        index: 1,
        value: "value",
        valueType: "float",
      },
      "/user/hand/right/input/thumbstick/y": {
        list: "axes",
        index: 3,
        value: "self",
        valueType: "float",
      },
      "/user/hand/right/input/thumbrest/touch": {
        list: "buttons",
        index: 6,
        value: "touched",
        valueType: "boolean",
      },
      "/user/hand/right/input/b/click": {
        list: "buttons",
        index: 5,
        value: "pressed",
        valueType: "boolean",
      },
    },
  };
}
